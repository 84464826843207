@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
  font-family: Inter !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d4d4d8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9ca3af;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #71717a;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  overflow: hidden;
}

body.zoom-active {
  transition: font-size 0.2s ease-in-out;

  .zoom-container {
    font-size: var(--zoom-level, 16px);
    // line-height: calc(1.5 * (var(--zoom-level, 16px) / 16));

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: calc(var(--zoom-level, 16px) * 1.5);
    }

    p,
    span {
      font-size: var(--zoom-level, 16px);
    }

    a {
      font-size: var(--zoom-level, 16px);
    }

    .bf-content * {
      font-size: 30px !important;
    }
  }
}

.form-item-space-between {
  .ant-form-item-label {
    display: flex;
    align-items: center;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
    width: 100%;
    padding-block: 12px;
  }

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
  }
}

.ant-modal-content {
  padding-bottom: 0 !important;
}

.ant-modal {
  .ant-modal-header {
    margin-inline: -24px;
    padding: 0 24px 8px 24px;
    border-bottom: 1px solid #e4e4e7;
  }

  .ant-modal-footer {
    padding: 12px 24px 12px 24px;
    margin-inline: -24px;
    border-top: 1px solid #e4e4e7;
  }

  .ant-card {
    border: 0;
  }
  .ant-card-body {
    padding: 8px 0 0 0;
  }
}

.table-full-height {
  table {
    height: 100%;
  }
}

.site-card-wrapper {
  background-color: white;
  border-bottom: 1px solid #e4e4e7;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.table-avatar {
  display: flex;
  align-items: center;
  font-size: 14;
  gap: 6px;

  .ant-avatar {
    flex-shrink: 0;
  }

  > span:not(.ant-avatar) {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/* ==========================================================================
  Ant Layout
========================================================================== */

.ant-layout {
  background-color: #fff;
}

/* ==========================================================================
  Ant Forms
========================================================================== */

.ant-form-item.lg-label label {
  color: var(--Text-NY-text, #2e3544);
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}

.ant-select-selector:hover,
.ant-picker:hover,
.ant-picker:focus-within {
  border: 1px solid #f97316 !important;
}

Button {
  flex-shrink: 0;
}

/* ==========================================================================
  Ant Tabs
========================================================================== */

.ant-tabs {
  .ant-tabs-nav {
    position: sticky !important;
    top: 0px;
    z-index: 99;
    background: white;
    margin: 0px !important;
  }

  .ant-tabs-nav-list {
    .ant-tabs-ink-bar {
      height: 4px;
    }
  }

  .ant-tabs-tab {
    padding: 15px 6px;
  }

  .ant-tabs-tab {
    color: black;
  }
}

.ant-tabs-content-holder {
  height: 100%;
  max-height: 100%;
  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
    max-height: 100%;
  }
}

.ant-tabs-content {
  padding-top: 16px;
}

.no-sticky > .ant-tabs-nav {
  position: static !important;
  z-index: 1 !important;
}

/* ==========================================================================
  Ant Table
========================================================================== */

.ant-table {
  .ant-table-content {
    padding: 0;
  }
}

.ant-table-wrapper .ant-table-thead:has(.ant-table-cell-fix-left) > tr > th {
  background-color: white !important;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: transparent !important;
}

// .ant-table-row:last-child {
//   .ant-table-cell {
//     border: none !important;
//   }
// }

.ant-empty {
  margin-block: 84px;
}

.ant-table-placeholder {
  // Necessary to hide empty placeholders under other elements (eg. modals, drawers, etc)
  z-index: 200 !important;

  .ant-table-cell {
    border: none !important;
  }
}

.projectDeliverable {
  .ant-table-cell {
    padding: 5px !important;
  }

  &.ant-table-wrapper .ant-table-row-expand-icon {
    position: absolute;
    background: transparent !important;
    left: 2px;
    margin-top: 0.75rem !important;
    width: 24px;
    display: flex;
    flex-direction: row;
    border-color: transparent !important;
  }

  &.ant-table-wrapper .ant-table-row-expand-icon-collapsed::before,
  &.ant-table-wrapper .ant-table-row-expand-icon-expanded::before {
    position: unset !important;
    display: block;
    margin-right: -3px;
    width: 10px;
    height: 1px;
    transform: rotate(45deg) !important;
  }

  &.ant-table-wrapper .ant-table-row-expand-icon-collapsed::after,
  &.ant-table-wrapper .ant-table-row-expand-icon-expanded::after {
    position: unset !important;
    display: block;
    width: 10px;
    height: 1px;
    transform: rotate(-45deg) !important;
  }

  &.ant-table-wrapper .ant-table-row-expand-icon {
    &.ant-table-row-expand-icon-collapsed {
      margin-top: 0px !important;
      transform: rotate(-90deg) !important;
    }
  }
}

.ant-pagination {
  margin-top: 4px;
  .ant-pagination-options {
    margin: 0 4px;
  }
}

.ant-table-wrapper.bottom-paginator {
  height: 100%;
  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      display: flex;
      flex-direction: column;
      height: 100%;

      .ant-table {
        flex-grow: 1;
        height: 100%;
      }
    }
  }
}

/* ==========================================================================
  Ant Typography
========================================================================== */

.ant-typography-edit-content {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  inset-inline-start: 0px !important;
}

/* ==========================================================================
  Ant Dropdown
========================================================================== */

.ant-dropdown-button {
  button {
    box-shadow: unset !important;
    border: 1px solid #e4e4e7 !important;
  }
}

/* ==========================================================================
  Ant tag
========================================================================== */

.ant-tag {
  border-color: #e4e4e7 !important;
}

/* ==========================================================================
  Highcharts
========================================================================== */

.highcharts-container {
  width: 100% !important;
  height: 100% !important;
}

.projects-chart,
.activities-chart,
.phases-chart,
.deliverables-chart {
  .highcharts-container {
    display: flex;
    justify-content: center;
  }
}

/* ==========================================================================
  Custom Components
========================================================================== */

.ant-menu-item.with-icon-title {
  .ant-menu-title-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}

.ant-menu-submenu-selected,
.ant-menu-item-selected {
  svg {
    path {
      fill: rgb(0, 102, 255) !important;
    }
  }
}

.ant-select.company-select {
  .ant-select-selector {
    background: var(--Surface-NY-surface, #1d4ed8);
    border: none;
    color: white;
    cursor: pointer !important;
  }

  .ant-select-arrow {
    color: white;
  }
}

.ant-statistic.brand-statistic {
  padding: 12px;

  .ant-statistic-title {
    color: var(--Text-NY-text-rest, #777da7);

    /* text-sm/leading-5/font-medium */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .ant-statistic-content-value {
    color: var(--Text-NY-text, #2e3544);

    /* text-6xl/leading-none/font-medium */
    font-size: 60px;
    font-weight: 500;
    line-height: 60px;
  }
}

.glass-effect {
  background-color: rgba(255, 255, 255, 0.3) !important;
  box-shadow: -4px 0px 30px 0px rgba(0, 0, 0, 0.13) !important;
  backdrop-filter: blur(30px) !important;
}

.company-blurred-panel {
  @extend .glass-effect;

  width: 320px;

  border-radius: var(--Sizes-md, 8px);
  border: 1px solid var(--Border-NY-border, #dadce7);

  color: var(--Text-NY-text, #2e3544);
}

/* ==========================================================================
  Relations
========================================================================== */

$relationSize: 15px;

.relationFull {
  height: $relationSize;
  width: $relationSize;
  background-color: #4992ff;
  border-radius: 50%;
  display: table-caption;
}

.relationPartial {
  height: $relationSize;
  width: $relationSize;
  border-radius: 50%;
  display: table-caption;
  border: 2px solid #4992ff;
}

.noRelation {
  height: $relationSize;
  width: $relationSize;
  border-radius: 50%;
  background-color: #dadce7;
  display: table-caption;
}

.white-shadow {
  box-shadow: -10px 0px 20px 0px #fff !important;
}

/* ==========================================================================
  Braft Editor
========================================================================== */

.bf-content {
  span {
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }
}

/* ==========================================================================
  MasterPlan
========================================================================== */

.project-master-plan {
  /*

.col-bottom1 p{
  white-space: pre-wrap;
}

.col-bottom1 {
  white-space: pre-wrap;

  .links{
    white-space: pre-wrap;
  }
}

.bold-subtitle {
  font-weight: bold;
}

#hoverId:hover{
  background-color:lightgray;

}

#hoverId {
  overflow: auto;
}


.hover-highlight:hover {
  background: lightgray !important;
}*/

  .product-review .ant-table-container table > thead > tr > th .highlight {
    background: #2f4897;
    color: #ffffff;
  }

  .masterplan .ant-table-container table > thead > tr > th .highlight {
    background: #2f4897;
    color: #ffffff;
  }

  // For Deliverable Table in MasterPlan
  .masterplan.masterplanTable {
    tr {
      .empty + .filled {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      .filled:last-child,
      .filled:has(+ .empty) {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
  }

  .first-row .ant-table-container table > thead > tr:first-child {
    display: none;
  }

  .second-row .ant-table-container table > thead > tr:first-child {
    display: none;
  }

  .blueStripes {
    background: repeating-linear-gradient(45deg, #ffffff, #fbfbfb 5px, #386e9b 5px, #2a7dc0 10px);
  }

  .redStripes {
    background: repeating-linear-gradient(45deg, #ffffff, #fdfdfd 5px, #eb1f1f 5px, #f23131 10px);
  }

  .greenStripes {
    background: repeating-linear-gradient(45deg, #ffffff, #fdfdfd 5px, #7dbe4b 5px, #62a132 10px);
  }

  .greyStripes {
    background: repeating-linear-gradient(45deg, #ffffff, #ffffff 5px, #a2a2a2 5px, rgb(142, 142, 142) 10px);
  }
}

/* ==========================================================================
  A3 (old)
========================================================================== */

.ant-list-split .ant-list-item {
  border-bottom: none;
}

.a3 {
  &-main {
    &-header {
      .endDateCheck {
        color: #ff1919;
        font-size: 16px;
      }

      .ant-row {
        .company-logo {
          display: flex;
          height: 100%;
          align-items: center;
        }

        .project {
          &-timeline {
            padding-left: 35px !important;

            .label {
              color: #989898;
              font-size: 14px;
            }

            .date {
              color: #012c60;
              font-size: 16px;
            }
          }

          &-update {
            padding-top: 5px;
            display: inline-block;
            @extend .label;
          }
        }
      }

      .header-semaphoreSection {
        display: inline-flex;
        list-style: none;
        .semaphore-section {
          margin: auto;
          text-align: center;
          .anticon-clock-circle {
            // background: #4cbb17;
            border-radius: 50px;
            padding: 8px;
          }
          .anticon-euro {
            // background: #e60000;
            border-radius: 50px;
            padding: 8px;
          }
          .anticon-line-chart {
            // background: #ff7800;
            border-radius: 50px;
            padding: 8px;
          }
        }
      }

      #headerLink {
        margin: auto;
      }

      .printIcon {
        color: #838485;
        font-size: 17px;
      }
    }

    &-body {
      padding: 10px 50px;

      // TODO check scrollbar position

      .section {
        background: white;
        box-shadow: 0 1px 3px #efefef;

        .closed-section {
          .ant-card-head {
            background: #fff !important;
            border-radius: 4px;
            box-shadow: 0 1px 3px #efefef;
          }
          .ant-card-body {
            display: none;
          }
        }
        .expanded-section {
          .ant-card-body {
            display: block;
          }
        }

        .ant-card {
          &-head {
            background: #f2f2f2 !important;
            height: unset !important;

            &-title {
              span,
              div {
                font-size: 18px;
                font-weight: bold;
                color: #023b72;
              }
            }
          }
          &-body {
            .custom-card-1,
            .custom-card {
              .ant-card-head {
                background: transparent !important;
                padding: 0 5px !important;
                height: unset !important;
                font-size: 18px;
                font-weight: 400;
                border-bottom: 1px solid #b7b7b7;
                &-title {
                  span,
                  a {
                    font-size: 18px !important;
                    font-weight: 100;
                    color: #023b72;
                    letter-spacing: 0.5px;
                  }
                }
                .ant-card-extra {
                  &:hover {
                    .anticon svg {
                      color: #40a9ff;
                    }
                  }
                }
              }
              .ant-card-body {
                padding: 5px;
                font-size: 16px;
              }
            }

            .ant-table-wrapper:not(.masterplan):not(.product-review) {
              max-height: 30vh;
              overflow: auto;
              padding-right: 15px;
              .ant-table-container {
                table {
                  border-collapse: separate;
                  border-spacing: 0 1em;
                }
              }
            }

            .kpiTableHeight {
              .ant-table-wrapper:not(.masterplan):not(.product-review) {
                max-height: 100% !important;
                overflow: auto;
                padding-right: 15px;
              }
            }

            .masterplan {
              .currentMonthIndicator {
                background-color: #023b72;
                color: #ffffff;
              }

              table {
                th {
                  background: #dde6ef;
                  color: #023b72;
                  font-size: 18px !important;
                  padding: 20px 5px !important;
                  text-align: center;
                  &:first-child {
                    font-size: 18px !important;
                    padding-left: 25px !important;
                  }
                  th:has(div) {
                    padding: 0 !important;
                    .highlight {
                      padding: 20px 5px !important;
                    }
                  }
                }
                tr {
                  td {
                    &.hover-highlight {
                      padding: 8px 4px;
                      border-top: 2px solid white !important;
                      border-bottom: 2px solid white !important;
                    }

                    &:first-child {
                      background: #dde6ef !important;
                      color: #023b72 !important;
                      padding: 5px 25px;
                      max-width: 50%;
                      a {
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                    }
                  }
                  &:first-child th:last-child {
                    border-top-right-radius: 5px;
                  }
                  &:first-child th:first-child {
                    border-top-left-radius: 5px;
                  }
                }
              }
            }

            .masterplanPrs {
              a .editIcon:hover {
                color: rgb(12, 35, 106);
              }

              td {
                border-bottom: 1px solid #91a1b1;
                .ant-divider-horizontal {
                  margin: 4px 0;
                  border-color: #d3d3d3;
                }
                a:hover {
                  color: #40a9ff !important;
                }
                .ant-space-vertical {
                  .ant-space-item {
                    margin-bottom: 0px !important;
                  }
                }
              }

              td:first-child {
                background-color: #dde6ef;
                border-bottom: 1px solid #91a1b1;
              }

              .currentMonthIndicatorRow td:first-child {
                background-color: #023b72 !important;

                span {
                  color: #ffffff !important;
                }
                td.ant-table-cell:hover {
                  background: unset;
                }
              }

              table {
                th {
                  background: #dde6ef;
                  color: #023b72;
                  font-size: 18px !important;
                  padding: 20px 5px !important;
                  text-align: center;
                  &:first-child {
                    font-size: 18px !important;
                    padding-left: 25px !important;
                  }
                  th:has(div) {
                    padding: 0 !important;
                    .highlight {
                      padding: 20px 5px !important;
                    }
                  }
                }
                tr {
                  td {
                    &.hover-highlight {
                      padding: 8px 4px;
                      border-top: 2px solid white !important;
                      border-bottom: 2px solid white !important;
                    }
                  }
                  &:first-child th:last-child {
                    border-top-right-radius: 5px;
                  }
                  &:first-child th:first-child {
                    border-top-left-radius: 5px;
                  }
                }
              }
            }

            .product-review {
              table {
                .ant-table-thead {
                  tr:first-child {
                    //display: none;
                  }
                }
                th {
                  background: #dde6ef !important;
                  color: #023b72 !important;
                  padding: 20px 5px !important;
                  text-align: center;
                }
                th:has(div) {
                  padding: 0 !important;
                  .highlight {
                    padding: 20px 5px !important;
                  }
                }
                tr {
                  background: white !important;
                  &:first-child th:last-child {
                    border-top-right-radius: 5px;
                  }
                  &:first-child th:first-child {
                    border-top-left-radius: 5px;
                  }
                  td {
                    &:hover {
                      background: #d7dfe7;
                    }
                    &.highlight,
                    &.hover-hightlight {
                      border-radius: 40px;
                    }
                    .col-bottom {
                      display: block;
                      text-align: center;
                    }
                  }
                }
              }
            }
            .ant-table-container {
              .ant-table {
                &-thead {
                  th {
                    text-transform: uppercase;
                    font-size: 15px;
                    // color: #989898;
                    padding: 0 10px;
                    font-weight: 100 !important;
                    // background: white;
                  }
                }
                &-tbody {
                  tr {
                    height: 40px;
                    background: #f2f2f2;
                    margin-bottom: 5px;
                    &.ant-table-row {
                      &:hover {
                        cursor: pointer;
                        td {
                          // border-color: #fafafa;
                          background-color: #d1dae3;
                        }
                      }
                    }
                    td {
                      &:first-child {
                        text-transform: uppercase;
                        text-align: left;
                        color: grey;
                        span {
                          color: #023b72;
                          font-weight: 100;
                          text-align: center;
                        }
                      }
                      span {
                        font-size: 16px;
                      }
                      .ant-empty-description {
                        color: #023b72;
                        font-size: 16px;
                      }
                    }
                  }
                }
              }
            }
            .project-status,
            .key-decision {
              .ant-table:not(.ant-table-empty) {
                tr {
                  &.status-1 {
                    td:first-child {
                      border-top-left-radius: 5px;
                      border-bottom-left-radius: 5px;
                      // border-left: 5px solid #4cbb17;
                    }
                  }
                  &.status-2 {
                    td:first-child {
                      border-top-left-radius: 5px;
                      border-bottom-left-radius: 5px;
                      // border-left: 5px solid #ff7800;
                    }
                  }
                  &.status-3 {
                    td:first-child {
                      border-top-left-radius: 5px;
                      border-bottom-left-radius: 5px;
                      // border-left: 5px solid #e60000;
                    }
                  }
                  &.status-4 {
                    td:first-child {
                      border-top-left-radius: 5px;
                      border-bottom-left-radius: 5px;
                      // border-left: 5px solid #FF7276;
                    }
                  }
                  &.status-5 {
                    td:first-child {
                      border-top-left-radius: 5px;
                      border-bottom-left-radius: 5px;
                      // border-left: 5px solid #fbd311;
                    }
                  }
                  &.status-6 {
                    td:first-child {
                      border-top-left-radius: 5px;
                      border-bottom-left-radius: 5px;
                      // border-left: 5px solid #fff;
                    }
                  }
                  td {
                    span,
                    a {
                      color: #323232;
                    }
                  }
                }
              }
            }
            .risks,
            .countermeasures {
              .row-list {
                &.risk-items {
                  background: #f5f5f5;
                  margin-bottom: 20px;
                  padding: 10px;
                  border-radius: 4px;
                }
                &.countermeasures-items {
                  background: #f5f5f5;
                  padding: 5px 10px;
                  margin-bottom: 20px;
                  border-radius: 4px;
                }
              }
            }
            .semaphore-section {
              .anticon-clock-circle {
                // background: #4cbb17;
                border-radius: 50px;
                padding: 8px;
              }
              .anticon-euro {
                // background: #e60000;
                border-radius: 50px;
                padding: 8px;
              }
              .anticon-line-chart {
                // background: #ff7800;
                border-radius: 50px;
                padding: 8px;
              }
            }
            .text-area {
              .ant-card-body {
                background-color: #eee;
                border-radius: 5px;
                margin-top: 15px;
                min-height: 130px;
                p > span {
                  background: transparent;
                }
              }
            }
            .kpi-select {
              width: 220px;
              display: inline-block;
              float: right;
            }
          }
          &-extra {
            font-size: 20px !important;
            padding-right: 12px;
          }

          &-bordered {
            border: 0;
          }
        }

        &-opened {
          box-shadow: 1px 1px 1px 1px #bbb5b51a;
          height: 30vh !important;
          background: white;

          .ant-table-tbody > tr > td span,
          a {
            font-size: 13px !important;
            color: #323232 !important;
          }

          .ant-table-tbody > tr > td {
            font-size: 13px !important;
          }

          .ant-table-thead > tr > th {
            font-size: 13px !important;
            font-weight: 600 !important;
          }
        }
      }

      .image-wrap img {
        max-width: 500px;
      }
    }

    .ant-modal {
      &-header {
        background: #fefefe;
      }
      &-footer {
        background: #fefefe;
      }
    }
  }
  &.modal {
  }
}

.masterplan {
  .delayText {
    font-size: 13px !important;
    font-weight: 500;
    background: #ffffffc4;
    padding: 3px;
  }

  .currentMonthIndicator {
    background-color: #023b72;
    color: #ffffff;
  }

  table {
    thead {
      th:not(:first-child) {
        writing-mode: vertical-lr;
        transform: scale(-1, -1);
        font-size: 14px !important;
      }

      th:first-child {
        font-size: 14px !important;
      }
    }

    th {
      background: #dde6ef;
      color: #023b72;
      font-size: 15px;
      padding: 8px 5px !important;
      text-align: center;
      &:first-child {
        font-size: 18px;
        padding-left: 25px !important;
      }
      th:has(div) {
        padding: 0 !important;
        .highlight {
          padding: 20px 5px !important;
        }
      }
    }
    tr {
      td {
        &.hover-highlight {
          padding: 8px 4px;
          border-top: 2px solid white !important;
          border-bottom: 2px solid white !important;
        }

        &:first-child {
          background: #dde6ef !important;
          color: #023b72 !important;
          padding: 5px 25px;
          text-transform: uppercase;
          max-width: 50%;
          white-space: normal;
          a {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      &:first-child th:last-child {
        border-top-right-radius: 5px;
      }
      &:first-child th:first-child {
        border-top-left-radius: 5px;
      }
    }
  }
}

.masterplanPrs {
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #e1e7ed;
  }

  a .editIcon:hover {
    color: rgb(12, 35, 106);
  }

  td {
    border-bottom: 1px solid #91a1b1;
    .ant-divider-horizontal {
      margin: 4px 0;
      border-color: #d3d3d3;
    }
    a:hover {
      color: #40a9ff !important;
    }
    .ant-space-vertical {
      .ant-space-item {
        margin-bottom: 0px !important;
      }
    }
  }

  td:first-child {
    background-color: #dde6ef;
    border-bottom: 1px solid #91a1b1;
    background-color: #dde6ef;
    border-bottom: 1px solid #91a1b1;

    span {
      text-transform: uppercase;
      color: #023b72;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .currentMonthIndicatorRow td:first-child {
    background-color: #023b72 !important;

    span {
      color: #ffffff !important;
    }
    td.ant-table-cell:hover {
      background: unset;
    }
  }

  table {
    th {
      background: #dde6ef;
      color: #023b72;
      font-size: 18px;
      padding: 20px 5px !important;
      text-align: center;
      &:first-child {
        font-size: 18px !important;
        padding-left: 25px !important;
      }
      th:has(div) {
        padding: 0 !important;
        .highlight {
          padding: 20px 5px !important;
        }
      }
    }
    tr {
      td {
        &.hover-highlight {
          padding: 8px 4px;
          border-top: 2px solid white !important;
          border-bottom: 2px solid white !important;
        }
      }
      &:first-child th:last-child {
        border-top-right-radius: 5px;
      }
      &:first-child th:first-child {
        border-top-left-radius: 5px;
      }
    }
  }
}

/* ==========================================================================
  Project zoom
========================================================================== */
