$tdSize: 32px;
$darkTdBackgroundColor: #f3f3f7;
$lineSize: 2px;
$lineColor: #4992ff;

.x-matrix {
  @media print and (color) {
    * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, min-content);
    width: fit-content;
    height: fit-content;
    grid-template-areas:
      'annualObjectiveProjectRelation projects projectKpiRelation space projectUserRelation'
      'yearObjectives center kpi space usersXmatrix'
      'longAnnualObjectiveRelation bigObjectives kpiLongObjectivesRelation space .';
  }

  #space {
    grid-area: space;
  }
  #annualObjectiveProjectRelation {
    grid-area: annualObjectiveProjectRelation;
  }
  #projects {
    grid-area: projects;
  }
  #projectKpiRelation {
    grid-area: projectKpiRelation;
  }
  #projectUserRelation {
    grid-area: projectUserRelation;
  }
  #yearObjectives {
    grid-area: yearObjectives;
  }
  #center {
    grid-area: center;
  }
  #kpi {
    grid-area: kpi;
  }
  #userCenter {
    grid-area: userCenter;
  }
  #usersXmatrix {
    grid-area: usersXmatrix;
  }
  #longAnnualObjectiveRelation {
    grid-area: longAnnualObjectiveRelation;
  }
  #bigObjectives {
    grid-area: bigObjectives;
  }
  #kpiLongObjectivesRelation {
    grid-area: kpiLongObjectivesRelation;
  }

  .grid-container-level2-noKpiAndUserCols {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    width: fit-content;
    height: fit-content;
    grid-template-areas:
      'ProjectChildProjectRelationLevel2 SubProjectsLevel2'
      'ProjectsLevel2 CenterSectionLevel2'
      'AnnualGoalProjectRelationLevel2 AnnualObjectivesLevel2'
      'verticalSpace verticalSpace'
      '. LongObjectivesLevel2';
  }

  .grid-container-level2 {
    display: grid;
    grid-template-columns: repeat(4, min-content);
    width: fit-content;
    height: fit-content;
    grid-template-areas:
      'ProjectChildProjectRelationLevel2 SubProjectsLevel2 ChildProjectKpiRelationLevel2 space UserProjectRelationLevel2'
      'ProjectsLevel2 CenterSectionLevel2 KpiLevel2 space UsersLevel2'
      'AnnualGoalProjectRelationLevel2 AnnualObjectivesLevel2 AnnualGoalKpiRelationLevel2 space .'
      'verticalSpace verticalSpace verticalSpace verticalSpace verticalSpace'
      '. LongObjectivesLevel2 . . .';
  }

  .grid-container-level2-noKpiCol {
    display: grid;
    grid-template-columns: repeat(4, min-content);
    width: fit-content;
    height: fit-content;
    grid-template-areas:
      'ProjectChildProjectRelationLevel2 SubProjectsLevel2 space UserProjectRelationLevel2'
      'ProjectsLevel2 CenterSectionLevel2 space UsersLevel2'
      'AnnualGoalProjectRelationLevel2 AnnualObjectivesLevel2 space .'
      'verticalSpace verticalSpace verticalSpace verticalSpace'
      '. LongObjectivesLevel2 . .';
  }

  .grid-container-level2-noUserCol {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    width: fit-content;
    height: fit-content;
    grid-template-areas:
      'ProjectChildProjectRelationLevel2 SubProjectsLevel2 ChildProjectKpiRelationLevel2'
      'ProjectsLevel2 CenterSectionLevel2 KpiLevel2'
      'AnnualGoalProjectRelationLevel2 AnnualObjectivesLevel2 AnnualGoalKpiRelationLevel2'
      'verticalSpace verticalSpace verticalSpace'
      '. LongObjectivesLevel2 .';
  }

  #vertical-space {
    grid-area: verticalSpace;
  }
  #AnnualObjectivesLevel2 {
    grid-area: AnnualObjectivesLevel2;
  }

  #CenterSectionLevel2 {
    grid-area: CenterSectionLevel2;
  }

  #ProjectChildProjectRelationLevel2 {
    grid-area: ProjectChildProjectRelationLevel2;
  }

  #SubProjectsLevel2 {
    grid-area: SubProjectsLevel2;
  }

  #ChildProjectKpiRelationLevel2 {
    grid-area: ChildProjectKpiRelationLevel2;
  }

  #UserProjectRelationLevel2 {
    grid-area: UserProjectRelationLevel2;
  }

  #KpiLevel2 {
    grid-area: KpiLevel2;
  }

  #UsersLevel2 {
    grid-area: UsersLevel2;
  }

  #AnnualGoalProjectRelationLevel2 {
    grid-area: AnnualGoalProjectRelationLevel2;
  }

  #LongObjectivesLevel2 {
    grid-area: LongObjectivesLevel2;
  }

  #AnnualGoalKpiRelationLevel2 {
    grid-area: AnnualGoalKpiRelationLevel2;
  }

  .matrix-row {
    max-width: 800px;
  }

  .matrix-row-vertical {
    max-height: 500px;
  }

  .selected-row {
    transition: all 0.3s;
    cursor: pointer;
    &.tw-bg-primary {
      * {
        color: white !important;
      }
    }
  }

  tr {
    max-height: $tdSize;

    td {
      padding: 0 0 !important;
      position: relative;
      border: 1px solid #e4e4e7;
      .selection-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          z-index: 10;
        }
        &.is-selected {
          background-color: white !important;
          border-top-color: white !important;
          border-left-color: white !important;
          border-right-color: white !important;
          span {
            z-index: 30;
          }
          span.anticon {
            z-index: 30 !important;
            background-color: white !important;
            padding: 4px;
            border: 2px solid $lineColor;
            border-radius: 30px;
          }
        }
        &.is-passing-through {
          background-color: white !important;
          border-top-color: white !important;
          border-left-color: white !important;
          border-right-color: white !important;
        }
      }
    }
  }

  .horizontal-lines {
    position: absolute;
    left: -1px;
    right: -1px;
    top: calc(50% - #{$lineSize / 2});
    height: $lineSize;
    background-color: $lineColor !important;
    z-index: 20;
    box-shadow:
      0 -1px 0 white,
      0 1px 0 white;
    &:not(.has-left-line) {
      left: 50%;
    }
    &:not(.has-right-line) {
      right: 50%;
    }
  }

  .vertical-lines {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: calc(50% - #{$lineSize / 2});
    width: $lineSize;
    background-color: $lineColor !important;
    z-index: 20;
    box-shadow:
      -1px 0 0 white,
      1px 0 0 white;
    &:not(.has-top-line) {
      top: 50%;
    }
    &:not(.has-bottom-line) {
      bottom: 50%;
    }
  }

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
    border-top: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-bottom: none;
  }

  @media print {
    .matrixComponentsPrint,
    .matrixComponentsHeadrPrint {
      display: none !important;
    }

    .matrixNameOnPrint {
      display: flex !important;
    }

    .site-layout-content,
    .ant-layout {
      background: #fff !important;
    }

    // print preview fix anual goals and print preview fix long term goals
    .rotateYear table tr td,
    .big-objectives table tr td {
      padding: 5px 6px !important;
    }
    // .rotateYear .ant-table-content table,
    // .big-objectives .ant-table-content table {
    //   table-layout: auto !important;
    // }
    .rotateYear .ant-table table,
    .big-objectives .ant-table table {
      height: 100% !important;
      position: inherit !important;
    }

    .rotateYear .ant-table-wrapper {
      min-height: 100% !important;
    }
  }

  .matrixComponentsPrint {
    display: block;
  }

  .matrixNameOnPrint {
    display: none;
  }

  .printIcon {
    color: #838485;
    font-size: 17px;
  }

  .filter-matrix {
    .select {
      width: 100%;
      margin: 10px;
    }
  }

  // .menu-drawer {
  //   .ant-drawer-body {
  //     padding: 5px;
  //   }

  //   .ant-menu-submenu-title {
  //     padding-left: 10px;
  //   }

  //   .ant-menu-inline {
  //     border-right: none;
  //   }

  //   .ant-drawer-content-wrapper {
  //     width: 400px !important;
  //   }
  // }

  // .mew_xmatrix_modal {
  //   .content-scroll {
  //     width: 100%;
  //     overflow: auto;
  //   }

  //   .button-wrapper {
  //     display: flex;
  //     flex-direction: row;
  //     justify-content: flex-end;
  //     border-bottom: 1px dashed #cfcfcf;
  //     margin-bottom: 10px;

  //     .ant-form-item {
  //       margin-bottom: 10px;
  //     }
  //   }

  //   .ant-row .ant-form-item {
  //     margin-bottom: 10px;
  //   }

  //   .ant-form-item-label {
  //     padding: 0 0 0;
  //   }
  // }

  // .active-xmatrix,
  // .no-active-xmatrix {
  //   font-size: 14px !important;
  //   margin-right: 8px !important;
  // }

  .active-xmatrix {
    color: #15bb23 !important;
    font-weight: 500;
  }

  .active-xmatrix-dropdown {
    .ant-dropdown-menu-title-content {
      color: #15bb23 !important;
      font-weight: 500;
    }
  }

  .no-active-xmatrix {
    color: #9d9d9d !important;
  }

  .selected-row-bg {
    background-color: #0066ff;
  }

  .x-matrix {
    .ant-spin-nested-loading {
      margin-left: auto;
      margin-right: auto;
      position: inherit;
    }
  }

  // .grid-container {
  //   display: grid;
  //   grid-template-columns: auto auto auto;
  //   background-color: #f1f1f1;
  //   padding: 10px;
  // }

  // .grid-container2 {
  //   display: grid;
  //   grid-template-columns: auto auto auto auto auto auto;
  //   background-color: #f1f1f1;
  //   padding: 10px;
  //   overflow: auto;
  // }

  // .grid-item {
  //   padding: 0;
  //   text-align: center;
  //   height: 100%;
  //   display: grid;
  // }

  .rotateKpi {
    border-left: 1px solid #d9d9d9;
    border-right: none !important;
    writing-mode: vertical-rl;
    white-space: nowrap;
    overflow: visible;

    .ant-table-cell {
      width: $tdSize !important;
    }
  }

  .rotateUsers {
    border-left: 1px solid #d9d9d9;
    position: relative;
    writing-mode: vertical-rl;
    white-space: nowrap;
    width: 100%;
    overflow: visible;
  }

  .rotateYear {
    border-left: 1px solid #d9d9d9;
    position: relative;
    writing-mode: vertical-rl;
    white-space: nowrap;
    overflow: visible;

    .ant-table-cell {
      width: $tdSize !important;
    }
  }

  .relation-table tr {
    td {
      background: $darkTdBackgroundColor;
      width: $tdSize;
      text-align: center;

      &:hover {
        background: #0066ff !important;
      }
    }
  }

  .ant-empty {
    font-size: 10px;

    .ant-empty-image svg {
      height: 80%;
      margin-bottom: 1px;
    }

    .ant-empty-image {
      margin-bottom: 1px;
      height: 0;
    }
  }

  .ant-empty-normal {
    margin: 0 8px;
    color: rgba(0, 0, 0, 0.25);
  }

  .rotateYearProject {
    border-bottom: 1px solid #d9d9d9;
    position: relative;
    transform: rotateY(180deg);
    transform-origin: 50% 100%;
    white-space: nowrap;
    //display: inline-block;
    overflow: visible;

    .ant-empty {
      transform: rotateY(180deg) !important;
    }

    .ant-table-cell {
      background-color: $darkTdBackgroundColor;
      width: $tdSize;
      height: $tdSize;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: $darkTdBackgroundColor;
    }

    table tr td {
      width: $tdSize;
      height: $tdSize;
      text-align: center;

      &:hover {
        background: #0066ff !important;
      }
    }
  }

  .rotateProjectUser {
    border-bottom: 1px solid #d9d9d9;
    position: relative;
    //transform: rotateY(180deg);
    transform-origin: 50% 100%;
    white-space: nowrap;
    //display: inline-block;
    overflow: visible;

    .ant-table table {
      tr {
        td:first-child {
          width: $tdSize;
          border-left: 1px solid #d9d9d9;
        }
      }
    }

    .ant-table-cell {
      background-color: $darkTdBackgroundColor;
      height: $tdSize;
      width: $tdSize;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: $darkTdBackgroundColor;
    }

    table tr td {
      width: $tdSize;
      text-align: center;

      &:hover {
        background: #0066ff !important;
      }
    }

    span {
      svg {
        font-size: 16px;
      }
    }
  }

  .rotateProjectKpi {
    border-bottom: 1px solid #d9d9d9;
    position: relative;
    white-space: nowrap;
    //display: inline-block;
    overflow: visible;

    .ant-table table {
      height: -webkit-fill-available;
    }

    .ant-table-cell {
      background-color: $darkTdBackgroundColor;
      height: $tdSize;
      width: $tdSize;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: $darkTdBackgroundColor;
    }

    table tr td {
      width: $tdSize;
      text-align: center;

      &:hover {
        background: #0066ff !important;
      }
    }
  }

  .rotateKpiLongObj {
    border-bottom: 1px solid #d9d9d9;
    position: relative;
    //transform: rotateY(180deg);
    transform-origin: 50% 100%;
    white-space: nowrap;
    //display: inline-block;
    overflow: visible;

    .ant-table table {
      height: -webkit-fill-available;
    }

    .ant-table-cell {
      background-color: $darkTdBackgroundColor;
      width: $tdSize;
      height: $tdSize;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: $darkTdBackgroundColor;
    }

    table tr td {
      width: $tdSize;
      text-align: center;

      &:hover {
        background: #0066ff !important;
      }
    }
  }

  .big-objectives {
    border-left: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;

    .width10 {
      height: $tdSize;
      width: $tdSize !important;
    }

    span {
      svg {
        color: #989898;
      }
    }
  }

  .projects {
    border-left: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;

    span {
      svg {
        color: #989898;
      }
    }

    .firstCol {
      color: #989898;
    }
  }

  .transformIcon {
    transform: rotate(180deg);
  }

  .noBorderTemporaryL2 {
    border: unset;
    background-color: #b4b4b4;
  }

  .hideUserGridItem {
    display: none;
  }

  .showKpiGridItem,
  .showUserGridItem {
    display: grid;
    background: #ffffffcc;

    .ant-table-container {
      .height10 {
        height: $tdSize !important;
      }
    }
  }

  .hideKpiGridItem {
    display: none;
  }

  .center-section {
    .user-grid-container {
      /* min-height: 300px; */
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto;
      height: 100%;
    }

    .center-grid-container {
      min-height: 500px;
      min-width: 800px;
      display: grid;
      grid-template-columns: $tdSize auto $tdSize;
      grid-template-rows: $tdSize auto $tdSize;
      height: 100%;
      .matrix-button {
        border: none;
      }
    }

    .center-grid-item {
      background: #ffffff;
      padding: 0;
      text-align: center;
    }

    .vertical-text {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      display: flex;
      justify-content: center;
    }

    .horizontal-text {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .center-grid-item.horizontal-text:nth-last-child(-n + 3) {
      border-bottom: none;
    }
  }

  .edit-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }



  .ant-table-container {
    border: none !important;
  }

  .ant-table-tbody {
    background-color: #ffffff;
  }

  .filter-card-wrapper {
    padding: 10px;
    background: #ffffff;
    margin: 5px 0 0 0;
  }

  .activeText {
    color: #73cc8b;
  }

  .inactiveText {
    color: #909090;
  }

  // .xmatrixTitle {
  //   font-size: 38px;
  // }

  // .xmatrixTitleDivider {
  //   border-left: 2px solid #dfd9d9;
  //   height: 3em;
  //   margin: 0 20px;
  // }

  // .mb20 {
  //   margin-bottom: 20px;
  // }

  // .filtriText {
  //   font-size: 18px;
  // }

  // .headerPositioning {
  //   display: flex !important;
  //   justify-content: center !important;
  //   align-items: center !important;
  // }

  // .headerPositioningEnd {
  //   display: flex !important;
  //   justify-content: center !important;
  //   align-items: end !important;
  //   .matrixComponentsHeadrPrint {
  //     .ant-space-item {
  //       margin-bottom: 0 !important;
  //       .ant-btn {
  //         padding-left: 0 !important;
  //       }
  //     }
  //   }
  // }

  // .leftPanel {
  //   font-size: 20px;
  //   color: $lineColor;
  // }

  // .firstToggle {
  //   padding-right: 0 !important;
  // }

  // .filterSection {
  //   background-color: #e0e0e0;
  // }

  // .ant-btn-primary,
  // .ant-btn-secondary {
  //   border-radius: 7px;
  // }

  // .ant-btn-primary {
  //   background-color: $lineColor;
  //   border: 1px solid $lineColor;

  //   &:hover {
  //     background-color: #022142;
  //     border: 1px solid #022142;
  //   }
  // }

  // .ant-btn-secondary {
  //   &:hover {
  //     color: $lineColor;
  //     border: 1px solid $lineColor;
  //   }
  // }

  // .ant-popover-content {
  //   .ant-popover-inner {
  //     border-radius: 7px;
  //   }
  // }

  // .ant-radio-checked .ant-radio-inner {
  //   border-color: $lineColor !important;
  // }

  // .ant-radio-checked .ant-radio-inner:after {
  //   background-color: $lineColor;
  // }

  // .ant-radio:hover .ant-radio-inner {
  //   border-color: $lineColor;
  // }

  // .ant-modal-content {
  //   .ant-btn-dashed,
  //   .ant-btn {
  //     border: 1px solid;
  //     border-radius: 7px;
  //   }

  //   .ant-btn:not(.ant-btn-primary) {
  //     &:hover {
  //       color: $lineColor !important;
  //       border-color: $lineColor !important;
  //     }
  //   }
  // }

  // .ant-menu {
  //   .ant-menu-item:hover,
  //   a {
  //     color: $lineColor;
  //   }
  // }

  .secondLevelXmatrix {
    .strategiaLungoPeriodo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $tdSize;
    }

    .annual-objectives-level-2 {
      .ant-table-cell {
        height: $tdSize;
        &.width10 {
          width: $tdSize !important;
        }
        &.width10L2 {
          width: 15px !important;
        }
        &.width35 {
          width: 35px !important;
        }
      }
    }
  }
}

// .ant-table {
//   counter-reset: rowIndex;

//   .ant-table-row {
//     counter-increment: rowIndex;
//     position: relative;
//     &::before {
//       content: counter(rowIndex) ' ';
//       position: absolute;
//       left: 50%;
//       top: 50%;
//       transform: translate(-50%, -50%);
//       background-color: red;
//       color: white;
//       padding: 2px 4px;
//     }
//   }
// }

.xMatrix-square-label {
  border-radius: 2px;
  width: 24px;
  margin-right: 4px;
  flex-shrink: 0;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statusActive {
  background-color: #22c55e !important;
  color: #ffffff;
}

.statusSospeso {
  background-color: #ffd81a !important;
  color: #ffffff;
}

.statusDraft {
  background-color: #e6e6e6 !important;
}

.statusConcluso {
  background-color: #ffffff !important;
}

.statusRevisionato {
  background-color: #ff8c1a !important;
  color: #ffffff;
}